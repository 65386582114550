import { isCourseBlockVisible } from '../../utils/temporary';
import { About } from './components/About';
import { AmazonBooks } from './components/AmazonBooks';
import { Books } from './components/Books';
import { Counters } from './components/Counters';
import { Facility } from './components/Facility';
import { HomeBanner } from './components/HomeBanner';
import { OurCourses } from './components/OurCourses';
import { OurPartners } from './components/OurPartners';
import { OurPosts } from './components/OurPosts';
import { OurWebinars } from './components/OurWebinars';
import { OurYouTubeChannel } from './components/OurYouTubeChannel';
import { Testimonials } from './components/Testimonials';
import { Welcome } from './components/Welcome';

export const Home = () => {
  return (
    <>
      <HomeBanner />
      <Welcome />
      <About />
      {isCourseBlockVisible() && <OurCourses />}
      <Books />
      <Facility />
      <AmazonBooks />
      <Counters />
      <OurWebinars />
      <OurYouTubeChannel />
      <OurPosts />
      <Testimonials />
      <OurPartners />
    </>
  );
};
