import { useTranslation } from 'react-i18next';

import styles from './MainFooterAbout.module.scss';
import { MainFooterSocials } from './MainFooterSocials';

export const MainFooterAbout = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.footerFollow}>{t('footer.socialLinksTitle')}</div>
      <MainFooterSocials />
    </>
  );
};
