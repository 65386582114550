import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Comments } from '../../../components/Comments';
import { useComments } from '../../../hooks/useComments';
import { IProductLessonShortContent } from '../../../models/IProducts';
import { REVIEW_PAGE } from '../../../models/IShared';
import { DescriptionTab } from './DescriptionTab/DescriptionTab';
import styles from './ProductTabs.module.scss';
import { VideoTab } from './VideoTab';
import { ContentTab } from './СontentTab';

export interface IVideoTab {
  title: string;
  description: string;
  preview?: string;
  linkVideo: string;
}
export interface IProps {
  videoTabProps: IVideoTab;
  contentTabProps: { list: IProductLessonShortContent[] };
  descriptionTabProps: { html: string | TrustedHTML };
}

export const ProductTabs: React.FC<IProps> = ({
  videoTabProps,
  contentTabProps,
  descriptionTabProps,
}) => {
  const { dataReviews, onChangeReview, onCreateReview } = useComments({
    type: REVIEW_PAGE.PRODUCT,
  });

  const { t } = useTranslation();

  const tabs = {
    [t('product.tab.description')]: <DescriptionTab {...descriptionTabProps} />,
    [t('product.tab.content')]: <ContentTab {...contentTabProps} />,
    [t('product.tab.video')]: <VideoTab {...videoTabProps} />,
    [t('product.tab.reviews')]: (
      <Comments
        count={dataReviews.count}
        reviews={dataReviews.reviews}
        onChangeReview={onChangeReview}
        onCreateReview={onCreateReview}
        type={REVIEW_PAGE.PRODUCT}
      />
    ),
  };

  const [activeTab, setActiveTab] = useState(t('product.tab.description'));

  const getTabClsn = (tab: string) =>
    cn(styles.tab, {
      [styles.activeTab]: activeTab === tab,
    });

  const onSetActiveTab = (tab: string) => () => setActiveTab(tab);

  return (
    <div className={styles.productTabs}>
      <div className={styles.wrapperTabs}>
        {Object.keys(tabs).map(tab => (
          <div key={tab} onClick={onSetActiveTab(tab)} className={getTabClsn(tab)}>
            {tab}
          </div>
        ))}
      </div>
      <div className={styles.tabContent}>{tabs[activeTab as keyof typeof tabs]}</div>
    </div>
  );
};
