import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useValidationService = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    username: Yup.string()
      .required(t('myProfile.child.enterUsernameValidationText'))
      .matches(/^\S*$/, 'Spaces are not allowed for username'),
    gender: Yup.string().required(t('myProfile.child.enterGenderValidationText')),
    first_name: Yup.string()
      .required(t('myProfile.child.enterFirstNameValidationText'))
      .matches(/^\S.*\S$/, t('common.formValidation.noSpacesValidationText')),
    last_name: Yup.string()
      .required(t('myProfile.child.enterLastNameValidationText'))
      .matches(/^\S.*\S$/, t('common.formValidation.noSpacesValidationText')),
    birthdate: Yup.string().required(t('myProfile.child.enterBirhdateValidationText')),
    password: Yup.string()
      .matches(/^\S*$/, t('common.formValidation.noSpacesPasswordValidationText'))
      .min(8, t('common.formValidation.passwordLengthValidationText'))
      .required(t('myProfile.child.enterPasswordValidationText')),
  });

  return validationSchema;
};
