import { useTranslation } from 'react-i18next';

import { amazonWorkbooksList, PAGES_ENUM } from '../../../../utils/constants';
import { AmazonWorkbookCard } from '../../../AmazonWorkbooksCatalog/AmazonWorkbookCard';
import { HomeSectionLayout } from '../HomeSectionLayout';
import styles from './AmazonBooks.module.scss';
import { AmazonBooksCarousel } from './AmazonBooksCarousel';

export const AmazonBooks = () => {
  const { t } = useTranslation();

  return (
    <HomeSectionLayout
      title={t('home.amazonBooks.title')}
      description={t('home.amazonBooks.description')}
      buttonLink={PAGES_ENUM.AMAZON_WORKBOOKS}
      buttonText={t('home.amazonBooks.buttonText')}
    >
      <AmazonBooksCarousel>
        {amazonWorkbooksList.slice(0, 4).map(item => {
          const { id, title, price, cover } = item;

          return (
            <AmazonWorkbookCard
              className={styles.amazonBookCard}
              key={id}
              id={id}
              title={title}
              price={price}
              cover={cover}
            />
          );
        })}
      </AmazonBooksCarousel>
      <div className={styles.amazonBooksDesktopWrapper}>
        {amazonWorkbooksList.slice(0, 4).map(item => {
          const { id, title, price, cover } = item;

          return (
            <AmazonWorkbookCard
              className={styles.amazonBookCard}
              key={id}
              id={id}
              title={title}
              price={price}
              cover={cover}
            />
          );
        })}
      </div>
    </HomeSectionLayout>
  );
};
