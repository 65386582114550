import { useTranslation } from 'react-i18next';

import { PAGES_ENUM } from '../../../utils/constants';
import styles from './MainFooter.module.scss';
import { MainFooterCard } from './MainFooterCard';
import { MainFooterAbout } from './MainFooterCard/MainFooterAbout';
import { MainFooterLinksList } from './MainFooterCard/MainFooterLinksList';
import { MainFooterNewsletter } from './MainFooterCard/MainFooterNewsletter';

const mainFooterEducationalTools = [
  {
    id: 1,
    link: PAGES_ENUM.PRODUCTS,
    title: 'Online Workbooks',
  },
  {
    id: 2,
    link: PAGES_ENUM.AMAZON_WORKBOOKS,
    title: 'Amazon Books',
  },
  {
    id: 3,
    link: PAGES_ENUM.ONLINE_GAMES,
    title: 'Online Games',
  },
  {
    id: 4,
    link: 'https://kidcanvas.smartkids-online.com/',
    title: 'Digital Art Studio',
    external: true,
  },
];

const mainFooterInformation = [
  {
    id: 1,
    link: '/',
    title: 'Home',
  },
  {
    id: 2,
    link: PAGES_ENUM.ABOUT,
    title: 'About Us',
  },
  {
    id: 3,
    link: PAGES_ENUM.PRIVACY_POLICY,
    title: 'Privacy Policy',
  },
  {
    id: 4,
    link: PAGES_ENUM.TERMS_OF_SERVICE,
    title: 'Terms of Service',
  },
  {
    id: 5,
    link: PAGES_ENUM.COPYRIGHT,
    title: 'Copyright',
  },
  {
    id: 6,
    link: PAGES_ENUM.FAQ,
    title: 'FAQ',
  },
  {
    id: 7,
    link: PAGES_ENUM.CONTACT_US,
    title: 'Contacts',
  },
];

export const MainFooter = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.mainFooter}>
      <div className="row g-0">
        <MainFooterCard title={t('footer.platformTitle')} className="col-xl-3 col-lg-3 col-sm-6">
          <MainFooterAbout />
        </MainFooterCard>
        <MainFooterCard
          title={t('footer.information.title')}
          className="col-xl-3 col-lg-3 col-sm-6"
        >
          <MainFooterLinksList list={mainFooterInformation} />
        </MainFooterCard>
        <MainFooterCard title="Educational Tools" className="col-xl-3 col-lg-3 col-sm-6">
          <MainFooterLinksList list={mainFooterEducationalTools} />
        </MainFooterCard>
        <MainFooterCard title="Join Our Community" className="col-xl-3 col-lg-3 col-sm-6">
          <MainFooterNewsletter />
        </MainFooterCard>
      </div>
      <div className={styles.copyrightAndDeveloperInfo}>
        <div className={styles.copyrightInfoContent}>
          <p className={styles.copyrightInfo}>{t('footer.copyright')}</p>
        </div>
        <p className={styles.developerInfo}>
          {t('footer.providedBy')}{' '}
          <a href="https://indago-dev.com/" rel="noopener noreferrer" target="_blank">
            IndagoDEV
          </a>
        </p>
      </div>
    </div>
  );
};
