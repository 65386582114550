import { useTranslation } from 'react-i18next';

import { LockIcon } from '../../../../../assets/svg/LockIcon';
import { OpenedLockIcon } from '../../../../../assets/svg/OpenedLockIcon';
import { NOTIFICATION_TYPES, useNotification } from '../../../../../hooks/useNotification';
import { useTypedDispatch } from '../../../../../hooks/useTypedDispatch';
import { useEditCollectionMutation } from '../../../../../services/collections';
import { ErrorNode } from '../../../../../utils/notifications';
import { Button } from '../../../../Button';
import { Modal } from '../../../Modal';
import { deleteModal } from '../../../ModalSlice';
import { CollectionModalLayout } from '../CollectionModalLayout';
import styles from './EditPublicCollectionModal.module.scss';

interface IProps {
  id: number;
  textSuccess: string;
  isPublic: boolean;
  title: string;
}

export const EditPublicCollectionModal: React.FC<IProps> = ({
  id,
  textSuccess,
  isPublic,
  title,
}) => {
  const { t } = useTranslation();
  const [edit, resEditCollection] = useEditCollectionMutation();

  const dispatch = useTypedDispatch();

  const onClose = () => {
    dispatch(deleteModal());
  };

  const onChangeStatusPublic = (e: React.FormEvent<HTMLFormElement>) => {
    edit({ body: { public: isPublic }, id });
  };

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resEditCollection.isSuccess,
    textSuccess,
    afterCreate: () => dispatch(deleteModal()),
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resEditCollection.isError,
    error: resEditCollection.error as ErrorNode,
  });

  return (
    <Modal ariaLabel={title}>
      <CollectionModalLayout
        title={title}
        icon={
          isPublic ? (
            <OpenedLockIcon className={styles.openCollectionIcon} />
          ) : (
            <LockIcon className={styles.openCollectionIcon} />
          )
        }
      >
        <p className={styles.openCollectionTxt}>
          {t('collections.modals.editPublicCollection.description')}
        </p>
        <div className={styles.openCollectionButtons}>
          <Button className={styles.openCollectionButton} onClick={onClose} isOrange>
            {t('collections.modals.cancelBtnText')}
          </Button>
          <Button className={styles.openCollectionButton} onClick={onChangeStatusPublic}>
            {t('collections.modals.confirmBtnText')}
          </Button>
        </div>
      </CollectionModalLayout>
    </Modal>
  );
};
