import { useTranslation } from 'react-i18next';

import { Loader } from '../../../../components/Loader';
import { MaxWidthContainer } from '../../../../components/MaxWidthContainer';
import { useGetSchoolFeaturesQuery } from '../../../../services/home';
import styles from './Facility.module.scss';
import { FacilityCard } from './FacilityCard';
import { FacilityCarousel } from './FacilityCarousel';

export const Facility = () => {
  const { data = [], isLoading } = useGetSchoolFeaturesQuery('');
  const { t } = useTranslation();

  if (isLoading) return <Loader />;

  return (
    <div className={styles.facility}>
      <MaxWidthContainer className={styles.facilityContainer}>
        <div className="row g-0 justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div className={styles.facilityHeading}>
              <h2 className={styles.facilityTitle}>{t('home.facility.title')}</h2>
              <p className={styles.facilityDescription}>{t('home.facility.description')}</p>
            </div>
          </div>
        </div>
        {data.length > 0 && (
          <>
            <FacilityCarousel>
              {data.map(item => (
                <FacilityCard key={item.id} card={item} />
              ))}
            </FacilityCarousel>
            <div className={styles.facilityContentDesktop}>
              {data.map(item => (
                <FacilityCard key={item.id} card={item} />
              ))}
            </div>
          </>
        )}
      </MaxWidthContainer>
    </div>
  );
};
