import { useField } from 'formik';
import { FC } from 'react';

import styles from './CollectionFormInput.module.scss';

type Props = {
  name: string;
  type: string;
  placeholder: string;
};

export const CollectionFormInput: FC<Props> = props => {
  const [field, meta] = useField(props);
  const isShowError = meta.touched && meta.error;

  return (
    <div className={styles.createCollectionFormInputWrapper}>
      <input {...field} {...props} className={styles.createCollectionFormInput} />
      {isShowError ? <div className={styles.error}>{meta.error}</div> : null}
    </div>
  );
};
