import firstBookAttachmentOne from './amazon/fb-attachment-1.png';
import firstBookAttachmentTwo from './amazon/fb-attachment-2.png';
import firstBookAttachmentThree from './amazon/fb-attachment-3.png';
import firstBookAttachmentFour from './amazon/fb-attachment-4.png';
import firstBookCoverImage from './amazon/fb-cover.png';
import secondBookAttachmentOne from './amazon/sb-attachment-1.png';
import secondBookAttachmentTwo from './amazon/sb-attachment-2.png';
import secondBookAttachmentThree from './amazon/sb-attachment-3.png';
import secondBookAttachmentFour from './amazon/sb-attachment-4.png';
import secondBookCoverImage from './amazon/sb-cover.png';
import thirdBookAttachmentOne from './amazon/tb-attachment-1.png';
import thirdBookAttachmentTwo from './amazon/tb-attachment-2.png';
import thirdBookAttachmentThree from './amazon/tb-attachment-3.png';
import thirdBookAttachmentFour from './amazon/tb-attachment-4.png';
import thirdBookCoverImage from './amazon/tb-cover.png';

export default {
  firstBookCoverImage,
  firstBookAttachmentOne,
  firstBookAttachmentTwo,
  firstBookAttachmentThree,
  firstBookAttachmentFour,
  secondBookCoverImage,
  secondBookAttachmentOne,
  secondBookAttachmentTwo,
  secondBookAttachmentThree,
  secondBookAttachmentFour,
  thirdBookCoverImage,
  thirdBookAttachmentOne,
  thirdBookAttachmentTwo,
  thirdBookAttachmentThree,
  thirdBookAttachmentFour,
};
