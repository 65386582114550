import styles from './MainFooterLinksList.module.scss';
import { MainFooterListItem } from './MainFooterListItem';

interface Link {
  id: number;
  link: string;
  title: string;
  external?: boolean;
}

type Props = {
  list: Link[];
};

export const MainFooterLinksList: React.FC<Props> = ({ list }) => {
  return (
    <div className={styles.mainFooterLinks}>
      <ul>
        {list.map(item => (
          <MainFooterListItem
            key={item.id}
            link={item.link}
            title={item.title}
            isExternal={item.external}
          />
        ))}
      </ul>
    </div>
  );
};
