import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { SaveIcon } from '../../assets/svg/SaveIcon';
import { SearchIcon } from '../../assets/svg/SearchIcon';
import { ShareIcon } from '../../assets/svg/ShareIcon';
import { Banner } from '../../components/Banner';
import { Button } from '../../components/Button';
import { Favorite } from '../../components/Favorite/Favorite';
import { LinkButton } from '../../components/LinkButton';
import { Loader } from '../../components/Loader';
import { MaxWidthContainer } from '../../components/MaxWidthContainer';
import { setModal } from '../../components/Modal/ModalSlice';
import { ModalTypes } from '../../components/Modal/types/enums/ModalTypes';
import { PopularTags } from '../../components/PopularTags/PopularTags';
import { ProductImgSlider } from '../../components/ProductImgSlider/ProductImgSlider';
import { RecommendedBooks } from '../../components/RecommendedMaterials/RecommendedBooks/RecommendedBooks';
import { RecommendedVideoCourses } from '../../components/RecommendedMaterials/RecommendedVideoCourses';
import { useAddToCart } from '../../hooks/useAddToCart';
import { useSaveToCollection } from '../../hooks/useSaveToCollection';
import { favoriteShowSuccessMessage, useSetFavorite } from '../../hooks/useSetFavorite';
import { ADD_TO_CART } from '../../models/ICart';
import { ISingleProduct, ProductProgressStatus } from '../../models/IProducts';
import { FAVORITE, RecommendationCourse, RecommendationProduct } from '../../models/IShared';
import { selectIsAuth } from '../../redux/Auth';
import {
  useGetProductProgressQuery,
  useGetProductQuery,
  useGetRecommendedCoursesBySingleProductQuery,
  useGetRecommendedProductsBySingleProductQuery,
  useStartProductMutation,
} from '../../services/product';
import { changeObjByFavorite } from '../../utils/changeObjByFavorite';
import { PAGES_ENUM } from '../../utils/constants';
import { handleError, handleInfo } from '../../utils/notifications';
import { isCourseBlockVisible } from '../../utils/temporary';
import styles from './Product.module.scss';
import { ProductAndPriceInfo } from './ProductAndPriceInfo';
import { ProductTabs } from './ProductTabs/ProductTabs';

export const Product = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector(selectIsAuth);
  const editorLink = `${process.env.REACT_APP_EDITOR_HOST}?productId=${id}&token=${authToken}`;

  const [startProduct] = useStartProductMutation();

  const { data: dataProduct, isFetching } = useGetProductQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: dataProductProgress,
    isFetching: isFetchingProductProgress,
    refetch,
  } = useGetProductProgressQuery(id, {
    skip: !authToken,
    refetchOnMountOrArgChange: true,
  });

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Recomendations start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const {
    data: dataProductsRecommendation,
    isLoading: isLoadingProductsRecommendation,
    isFetching: isFetchingProductsRecommendation,
    isError: isErrorProductsRecommendation,
  } = useGetRecommendedProductsBySingleProductQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: dataCoursesRecommendation,
    isLoading: isLoadingCoursesRecommendation,
    isFetching: isFetchingCoursesRecommendation,
    isError: isErrorCoursesRecommendation,
  } = useGetRecommendedCoursesBySingleProductQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  interface IRecommendation {
    courses: RecommendationCourse[] | null;
    products: RecommendationProduct[] | null;
  }

  const [recommendation, setRecommendation] = useState<IRecommendation>({
    courses: null,
    products: null,
  });

  useEffect(() => {
    if (isFetchingCoursesRecommendation || isErrorCoursesRecommendation) return;
    setRecommendation(pre => ({ ...pre, courses: dataCoursesRecommendation?.results }));
  }, [isFetchingCoursesRecommendation]);

  useEffect(() => {
    if (isFetchingProductsRecommendation || isErrorProductsRecommendation) return;
    setRecommendation(pre => ({ ...pre, products: dataProductsRecommendation?.results }));
  }, [isFetchingProductsRecommendation]);

  const isShowCourseRecommendation =
    recommendation?.courses && recommendation?.courses?.length !== 0;
  const isShowProductsRecommendation =
    recommendation?.products && recommendation?.products?.length !== 0;

  interface IOnFavorite {
    type: FAVORITE.COURSE | FAVORITE.PRODUCT;
    typeItem: 'products' | 'courses';
  }

  const onFavoriteRecommendation =
    ({ type, typeItem }: IOnFavorite) =>
    async (item: RecommendationCourse | RecommendationProduct) => {
      const res = (await setFavorite({
        id: item.pk,
        body: { type, favorite: !item.is_favorite },
      })) as {
        data: {
          status: number;
        };
      };

      if (res?.data?.status >= 200 && res?.data?.status < 400) {
        setRecommendation(changeObjByFavorite(typeItem, item));
        favoriteShowSuccessMessage(!item.is_favorite, t);
      }
    };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Recomendations end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const [data, setData] = useState(dataProduct);

  const breadcrumbs = useMemo(
    () => [
      {
        id: 1,
        link: data?.is_purchased ? PAGES_ENUM.MY_PRODUCTS : PAGES_ENUM.PRODUCTS,
        title: data?.is_purchased ? t('myProducts.breadcrumbTitle') : t('products.breadcrumbTitle'),
      },
    ],
    [data]
  );

  useEffect(() => {
    if (isFetching) return;
    setData(dataProduct);
  }, [isFetching]);

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> AddToCollection start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const onChangeStatusCollectionInData = (obj: { [key: string]: any }) => {
    const { isTrue } = obj;

    setData(pre => ({ ...pre, in_collection: isTrue }));
  };

  const { onSaveToCollection } = useSaveToCollection({
    func: onChangeStatusCollectionInData,
  });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> AddToCollection end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const { setCartFavorite: setFavorite, resCartFavorite } = useSetFavorite();
  const onFavorite = async () => {
    const res = (await setFavorite({
      id: data?.pk,
      body: { type: FAVORITE.PRODUCT, favorite: !data.is_favorite },
    })) as {
      data: {
        status: number;
      };
    };

    if (res?.data?.status >= 200 && res?.data?.status < 400) {
      setData(pre => ({ ...pre, is_favorite: !data.is_favorite }));
      favoriteShowSuccessMessage(!data.is_favorite, t);
    }
  };

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Recommended Courses Collection start <<<<<<<<<<<<<<<<<<<<<<<<<<

  const onChangeCollectionStatusInRecommendedCourses = (obj: { [key: string]: any }) => {
    const { id, isTrue } = obj;

    setRecommendation(pre => ({
      ...pre,
      courses: pre['courses'].map(course =>
        course.pk === id ? { ...course, in_collection: isTrue } : course
      ),
    }));
  };

  const { onSaveToCollection: onSaveToCollectionRecommendedCourse } = useSaveToCollection({
    func: onChangeCollectionStatusInRecommendedCourses,
  });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Recommended Courses Collection end <<<<<<<<<<<<<<<<<<<<<<<<<<
  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Recommended Products Collection start <<<<<<<<<<<<<<<<<<<<<<<

  const onChangeCollectionStatusInRecommendedProducts = (obj: { [key: string]: any }) => {
    const { id, isTrue } = obj;

    setRecommendation(pre => ({
      ...pre,
      products: pre['products'].map(product =>
        product.pk === id ? { ...product, in_collection: isTrue } : product
      ),
    }));
  };

  const { onSaveToCollection: onSaveToCollectionRecommendedProduct } = useSaveToCollection({
    func: onChangeCollectionStatusInRecommendedProducts,
  });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Recommended Products Collection end <<<<<<<<<<<<<<<<<<<<<<<<<<

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Add to cart start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const { addToCart } = useAddToCart({});
  const onAddToShoppingCart = () =>
    addToCart({ id: data?.pk, body: { type: ADD_TO_CART.PRODUCT } });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Add to cart end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  interface IImage {
    attachment: string;
    attachment_type: string;
    pk: number;
  }

  const firstImage: IImage = {
    attachment: data?.preview,
    attachment_type: 'IMAGE',
    pk: 0,
  };

  let attachmentList: IImage[];

  if (data?.preview && data?.attachments) {
    attachmentList = [firstImage, ...data.attachments];
  } else if (data?.preview && !data?.attachments) {
    attachmentList = [firstImage];
  } else if (data?.attachments && !data?.preview) {
    attachmentList = data.attachments;
  } else {
    attachmentList = [];
  }

  if (
    isFetching ||
    isFetchingProductProgress ||
    isLoadingProductsRecommendation ||
    isLoadingCoursesRecommendation
  )
    return <Loader />;

  const onShareModal = () => {
    dispatch(
      setModal({
        modalType: ModalTypes.SHARE_PAGE_MODAL,
      })
    );
  };

  const onProgressWarningModal = () => {
    if (!id) return;

    if (!authToken) {
      handleError(t('errors.NOT_AUTHORIZED'));
      navigate(PAGES_ENUM.SIGN_IN);

      return;
    }

    dispatch(
      setModal({
        modalType: ModalTypes.PRODUCT_PROGRESS_WARNING_MODAL,
        modalProps: { productId: id, token: authToken },
      })
    );
  };

  const handleStartProductBtnClick = async () => {
    if (!authToken) {
      handleError(t('errors.NOT_AUTHORIZED'));
      navigate(PAGES_ENUM.SIGN_IN);

      return;
    } else if (!data?.is_purchased) {
      handleInfo(t('common.notABoughtProduct'));

      return;
    }

    try {
      const data = await startProduct(id).unwrap();

      if (data?.status === true) {
        refetch();
        const linkElement = document.createElement('a');

        linkElement.setAttribute('target', '_blank');
        linkElement.setAttribute('rel', 'noopener noreferrer');
        linkElement.href = editorLink;
        linkElement.click();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Banner title={t('product.bannerTitle')} breadcrumbs={breadcrumbs} />
      <div className={styles.product}>
        <MaxWidthContainer>
          <Link
            to={`${PAGES_ENUM.PRODUCTS}/?lesson_themes=${data?.subject_title.pk}`}
            className={styles.productSubjectMobile}
          >
            <SearchIcon />
            {data?.subject_title?.name}
          </Link>
          <div className={styles.productImageAndInfo}>
            <div className={styles.productAndPriceInfoMobileAndTablet}>
              <ProductAndPriceInfo
                title={data?.title}
                price={data?.price}
                discountPrice={data?.discount_price}
                authorLink={`${PAGES_ENUM.STAFF_DETAILS}/${data?.creator?.pk}`}
                author={data?.creator?.full_name}
                ageInfo={data?.ages?.name}
                isPurchased={data?.is_purchased}
              />
            </div>
            <div className={styles.productImgSliderAndSubject}>
              <Link
                to={`${PAGES_ENUM.PRODUCTS}/?lesson_themes=${data?.subject_title.pk}`}
                className={styles.productSubjectDesktop}
              >
                <SearchIcon />
                {data?.subject_title?.name}
              </Link>
              <ProductImgSlider
                className={styles.productImageSlider}
                imgList={attachmentList}
                imageAlt={t('product.sliderImageAltText')}
              />
            </div>
            <div className={styles.productInfoContent}>
              <div className={styles.productAndPriceInfoDesktop}>
                <ProductAndPriceInfo
                  title={data?.title}
                  price={data?.price}
                  discountPrice={data?.discount_price}
                  authorLink={`${PAGES_ENUM.STAFF_DETAILS}/${data?.creator?.pk}`}
                  author={data?.creator?.full_name}
                  ageInfo={data?.ages?.name}
                  isPurchased={data?.is_purchased}
                />
              </div>
              <div>
                <p
                  className={styles.productDescription}
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
                <div className={styles.productButtonsContainer}>
                  <>
                    {!data?.is_purchased ? (
                      <Button
                        className={cn(styles.productBtnAlt, {
                          [styles.productBtnDisabled]: !data?.is_purchased,
                        })}
                        onClick={() => {
                          if (!authToken) {
                            handleError(t('errors.NOT_AUTHORIZED'));
                            navigate(PAGES_ENUM.SIGN_IN);

                            return;
                          }
                          handleInfo(t('common.notABoughtProduct'));
                        }}
                      >
                        {t('product.download')}
                      </Button>
                    ) : (
                      <LinkButton
                        className={styles.productBtnAlt}
                        link={data?.download_archive}
                        isTargetBlank
                        download
                      >
                        {t('product.download')}
                      </LinkButton>
                    )}

                    <>
                      {data?.json_file && (
                        <>
                          {dataProductProgress?.status === ProductProgressStatus.STARTED ? (
                            <Button
                              onClick={onProgressWarningModal}
                              className={cn(styles.productBtn, {
                                [styles.productBtnDisabled]: !data?.is_purchased,
                              })}
                              isBlue
                            >
                              {t('product.progressWarningModal.startAgainBtnText')}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleStartProductBtnClick}
                              className={cn(styles.productBtn, {
                                [styles.productBtnDisabled]: !data?.is_purchased,
                              })}
                              isBlue
                            >
                              {t('product.openOnWebsite')}
                            </Button>
                          )}
                          {dataProductProgress?.status === ProductProgressStatus.STARTED && id && (
                            <LinkButton
                              className={cn(styles.productBtn, {
                                [styles.productBtnDisabled]: !data?.is_purchased,
                              })}
                              isOrange
                              link={editorLink}
                              isTargetBlank
                            >
                              {t('product.continueTheTaskBtnText')}
                            </LinkButton>
                          )}
                        </>
                      )}
                    </>
                    {!data?.is_purchased && (
                      <Button onClick={onAddToShoppingCart} className={styles.productBtn} isOrange>
                        {t('product.addToCart')}
                      </Button>
                    )}
                  </>
                </div>
                <div className={styles.productFavoriteAndSaveButtonsContainer}>
                  <button
                    className={styles.productSaveBtn}
                    onClick={() => {
                      if (!authToken) {
                        handleError(t('errors.NOT_AUTHORIZED'));
                        navigate(PAGES_ENUM.SIGN_IN);

                        return;
                      }
                      
                      onSaveToCollection({ pk: data?.pk } as ISingleProduct)();
                    }}
                  >
                    <SaveIcon className={cn({ [styles.saveIconColored]: data?.in_collection })} />
                    {data?.in_collection
                      ? t('product.addedToCollectionText')
                      : t('product.addToCollectionText')}
                  </button>
                  <button
                    className={styles.productFavoriteBtn}
                    onClick={() => {
                      if (!authToken) {
                        handleError(t('errors.NOT_AUTHORIZED'));
                        navigate(PAGES_ENUM.SIGN_IN);

                        return;
                      }
                      onFavorite();
                    }}
                    disabled={resCartFavorite.isLoading}
                  >
                    <Favorite isLike={data?.is_favorite} />
                    {data?.is_favorite
                      ? t('product.removeFromFavorites')
                      : t('product.addToFavorites')}
                  </button>
                  <button className={styles.shareBtn} onClick={onShareModal}>
                    <ShareIcon />
                    {t('product.share')}
                  </button>
                </div>
                <PopularTags
                  className={styles.productPopularTags}
                  tags={data?.tags}
                  isTitleNeeded={false}
                  link={PAGES_ENUM.PRODUCTS}
                  isPageRedirectByClickTags
                />
              </div>
            </div>
          </div>
          <ProductTabs
            videoTabProps={{
              title: data?.video_title,
              description: data?.video_description,
              linkVideo: data?.video,
            }}
            contentTabProps={{ list: data?.product_lesson_short_content }}
            descriptionTabProps={{ html: data?.full_text }}
          />
          {(isShowCourseRecommendation || isShowProductsRecommendation) && (
            <div className={styles.recommendedMaterials}>
              {isCourseBlockVisible() && isShowCourseRecommendation && (
                <RecommendedVideoCourses
                  link={`${PAGES_ENUM.COURSES}`}
                  list={recommendation.courses as RecommendationCourse[]}
                  onFavorite={onFavoriteRecommendation({
                    typeItem: 'courses',
                    type: FAVORITE.COURSE,
                  })}
                  onCollection={onSaveToCollectionRecommendedCourse}
                />
              )}

              {isShowProductsRecommendation && (
                <RecommendedBooks
                  link={`${PAGES_ENUM.PRODUCTS}`}
                  list={recommendation?.products as RecommendationProduct[]}
                  onFavorite={onFavoriteRecommendation({
                    typeItem: 'products',
                    type: FAVORITE.PRODUCT,
                  })}
                  onCollection={onSaveToCollectionRecommendedProduct}
                  isShowMobileCommonTitle={!isShowCourseRecommendation}
                />
              )}
            </div>
          )}
        </MaxWidthContainer>
      </div>
    </>
  );
};
