import { useTranslation } from 'react-i18next';

import { LinkButton } from '../../../../components/LinkButton';
import { MaxWidthContainer } from '../../../../components/MaxWidthContainer';
import styles from './OurYouTubeChannel.module.scss';
export const OurYouTubeChannel = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.youTube}>
      <MaxWidthContainer>
        <div className={styles.youTubeContent}>
          <h2>{t('home.youTube.title')}</h2>
          <p>{t('home.youTube.description')}</p>
          <LinkButton
            className={styles.youTubeLink}
            link="https://www.youtube.com/@SmartKids-Online"
            isTargetBlank
            isOrange
          >
            {t('home.youTube.buttonText')}
          </LinkButton>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
