import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useValidationService = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/(^\S$)|(^\S.*\S$)/, t('common.formValidation.noSpacesValidationText'))
      .required('Please fill in this field'),
    description: Yup.string()
      .matches(/(^\S$)|(^\S.*\S$)/, t('common.formValidation.noSpacesValidationText'))
  });

  return validationSchema;
};
