import Tippy from '@tippyjs/react';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import defaultCardImage from '../../assets/img/default-preview.png';
import { CheckIcon } from '../../assets/svg/CheckIcon';
import { MoreIcon } from '../../assets/svg/MoreIcon';
import { SaveIcon } from '../../assets/svg/SaveIcon';
import { IProduct } from '../../models/IProducts';
import { CollectionControlPanel } from '../../pages/Collections/CollectionControlPanel';
import { selectIsAuth } from '../../redux/Auth';
import { PAGES_ENUM } from '../../utils/constants';
import { Button } from '../Button';
import { Favorite } from '../Favorite/Favorite';
import styles from './BookCard.module.scss';

type Props = {
  card: IProduct;
  link?: string;
  onFavorite?: () => void;
  onAddToShoppingCart?: () => void;
  collectionControl?: boolean;
  collectionId?: number;
  onCollection: () => void;
  isFavoriteBook?: boolean;
  isDisabled?: boolean;
};

export const BookCard: React.FC<Props> = ({
  card,
  link,
  onFavorite,
  onAddToShoppingCart,
  collectionControl = false,
  collectionId,
  onCollection,
  isFavoriteBook,
  isDisabled,
}) => {
  const {
    title,
    subject_title,
    preview,
    price = '',
    discount_price = '',
    ages,
    product_card_block = [],
    in_collection,
    creator,
    is_favorite,
    is_purchased,
  } = card;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isBoughtProductsPage = location.pathname.includes(PAGES_ENUM.MY_PRODUCTS);
  const isCurrFavorite = is_favorite ?? isFavoriteBook;
  const [isControlPanelOpened, setIsControlPanelOpened] = useState(false);
  const [isFavorite, setIsFavorite] = useState(isCurrFavorite);
  const buttonRef = useRef(null);

  useEffect(() => {
    setIsFavorite(isCurrFavorite);
  }, [isCurrFavorite]);

  const onWillFavorite = () => {
    setIsFavorite(pre => !pre);
    onFavorite && onFavorite();
  };

  // >>>>>>>>>>> Favorite end <<<<<<<<<<<

  const onWillAddToShoppingCart = () => onAddToShoppingCart && onAddToShoppingCart();

  const isAuth = useSelector(selectIsAuth);

  const handleCardClick = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <div className={styles.bookCard} onClick={handleCardClick}>
      <div className={styles.bookCardHeader}>
        <Link
          to={`${isBoughtProductsPage ? PAGES_ENUM.MY_PRODUCTS : PAGES_ENUM.PRODUCTS}/?subjects=${
            subject_title?.pk
          }`}
          onClick={e => e.stopPropagation()}
        >
          {subject_title?.name}
        </Link>
        {collectionControl && (
          <button
            className={styles.bookCardMoreBtn}
            onClick={e => {
              e.stopPropagation();
              setIsControlPanelOpened(!isControlPanelOpened);
            }}
            ref={buttonRef}
            aria-label="manage collection"
          >
            <MoreIcon />
          </button>
        )}
        {isControlPanelOpened && (
          <CollectionControlPanel
            className={styles.bookCardControlPanel}
            onClose={() => setIsControlPanelOpened(false)}
            forwardedRef={buttonRef}
            itemId={card.pk}
            collectionId={collectionId}
            isCollectionItem
          />
        )}
      </div>
      <img
        className={styles.bookCardImage}
        src={preview || defaultCardImage}
        width="255"
        height="270"
        alt={t('bookCard.bookImageAltText')}
      />
      <div className={styles.bookCardFooter}>
        <div className={styles.bookCardTitleContainer}>
          <Link to={link ?? ''} onClick={e => e.stopPropagation()}>
            <h4 className={styles.bookCardFooterTitle}>{title}</h4>
          </Link>
        </div>
        {!is_purchased && (
          <div className={styles.bookCardPrices}>
            {(discount_price || (discount_price === 0 && price !== 0)) ? (
              <>
                <span className={styles.bookCardDiscountPrice}>{discount_price}&#36;</span>
                <span className={cn(styles.bookCardPrice, styles.bookCardPriceCrossedOut)}>
                  {price}&#36;
                </span>
              </>
            ) : (
              <span className={styles.bookCardPrice}>{price}&#36;</span>
            )}
          </div>
        )}
        <div className={styles.bookCardInfo}>
          <Link
            to={`${isBoughtProductsPage ? PAGES_ENUM.MY_PRODUCTS : PAGES_ENUM.PRODUCTS}/?ages=${ages?.pk}`}
            className={styles.ages}
            onClick={e => e.stopPropagation()}
          >
            <p className={styles.bookCardAgeInfo}>{ages?.name}</p>
          </Link>
          {isAuth && (
            <div className={styles.bookCardIconsContainer}>
              <Tippy
                content={
                  in_collection ? t('common.deleteFromCollection') : t('common.addToCollection')
                }
              >
                <button
                  className={styles.bookCardIconBtn}
                  onClick={e => {
                    e.stopPropagation();
                    onCollection();
                  }}
                  aria-label={
                    in_collection ? t('common.deleteFromCollection') : t('common.addToCollection')
                  }
                  disabled={isDisabled}
                >
                  <SaveIcon className={cn({ [styles.bookCardSaveIconColored]: in_collection })} />
                </button>
              </Tippy>
              <Tippy
                content={isFavorite ? t('common.removeFromFavorites') : t('common.addToFavorites')}
              >
                <button
                  className={styles.bookCardIconBtn}
                  onClick={e => {
                    e.stopPropagation();
                    onWillFavorite();
                  }}
                  aria-label={
                    isFavorite ? t('common.removeFromFavorites') : t('common.addToFavorites')
                  }
                  disabled={isDisabled}
                >
                  <Favorite isLike={isFavorite} />
                </button>
              </Tippy>
            </div>
          )}
        </div>
      </div>
      <div className={styles.bookCardCurriculumInfo}>
        <div className={styles.bookCardCurriculumInfoContainer}>
          <ul className={styles.bookCardCurriculumList}>
            {product_card_block.map(task => {
              const { title, pk } = task;
              const num = parseInt(title) || '';

              return (
                <li key={pk} className={styles.bookCardCurriculumItem}>
                  <strong>{num}</strong>
                  {title.slice(num.toString().length)}
                </li>
              );
            })}
          </ul>
          <p className={styles.bookCardCurriculumAuthor}>
            {t('bookCard.authorTitle')} {creator?.full_name}
          </p>
        </div>
        {is_purchased ? (
          <div className={styles.bookCardGratitude}>
            <CheckIcon />
            <span>{t('bookCard.purchasedBookTitle')}</span>
          </div>
        ) : (
          <Button
            className={styles.bookCardShoppingCartBtn}
            onClick={e => {
              e.stopPropagation();
              onWillAddToShoppingCart();
            }}
            isOrange
            aria-label={t('bookCard.addToCartBtnText')}
          >
            {t('bookCard.addToCartBtnText')}
          </Button>
        )}
      </div>
    </div>
  );
};
