import { useTranslation } from 'react-i18next';

import { LinkButton } from '../../../../components/LinkButton';
import { MaxWidthContainer } from '../../../../components/MaxWidthContainer';
import { PAGES_ENUM } from '../../../../utils/constants';
import styles from './About.module.scss';

export const About = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.about}>
      <MaxWidthContainer>
        <div className="row g-0 justify-content-end">
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-12">
            <h2 className={styles.aboutTitle}>{t('home.about.title')}</h2>
            <p className={styles.aboutDescription}>
              At <strong>Smart Kids Online</strong>, we make learning fun and easy. Our tools are
              designed to help your child explore new skills and creativity in an engaging way.
            </p>
            <h3 className={styles.aboutSubtitle}>Interactive Online Workbooks</h3>
            <p className={styles.aboutDescription}>
              Our online workbooks are more than just pages to fill out - they&apos;re adventures in
              learning! With topics like reading, math, and logic, your child can learn at their own
              pace on any device. Whether they’re on a computer, tablet, or smartphone, learning is
              always within reach.
            </p>
            <h3 className={styles.aboutSubtitle}>Accessible Anywhere, Anytime</h3>
            <p className={styles.aboutDescription}>
              No need to worry about where or when your child learns. Our workbooks are designed for
              flexible learning, so your child can dive into lessons whenever and wherever they are.
              It’s like having a classroom in your pocket!
            </p>
            <h3 className={styles.aboutSubtitle}>Creative Digital Art Space</h3>
            <p className={styles.aboutDescription}>
              Creativity knows no bounds with our digital art tools. Whether your child loves to
              draw, color, or design, our online editor Kid Canvas lets them express their imagination freely.
              It’s a space where learning meets creativity, all in one place.
            </p>
            <h3 className={styles.aboutSubtitle}>Engaging and Fun</h3>
            <p className={styles.aboutDescription}>
              Every activity on our platform is made to be enjoyable. From solving puzzles to
              exploring new concepts, our resources keep your child interested and excited to learn
              more.<br />It’s education that doesn’t feel like school - it&apos;s an adventure!
              <br />
              With <strong>Smart Kids Online</strong>, your child can explore, learn, and grow in a
              way that’s both fun and educational. We&apos;re here to support their journey every
              step of the way.
            </p>

            <div className={styles.aboutBtnsWrapper}>
              <LinkButton
                className={styles.aboutBtn}
                link={process.env.REACT_APP_EDITOR_HOST}
                isTargetBlank
                isBlue
              >
                {t('home.about.buttonText')}
              </LinkButton>
              <LinkButton
                className={styles.aboutBtn}
                to={PAGES_ENUM.PRODUCTS}
                isOrange
                isTargetBlank
              >
                {t('home.about.onlineBooksBtnText')}
              </LinkButton>
            </div>
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
