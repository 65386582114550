import { useTranslation } from 'react-i18next';

import { BookIcon } from '../../../assets/svg/BookIcon';
import { PurchaseHistoryIcon } from '../../../assets/svg/PurchaseHistoryIcon';
import { SaveIcon } from '../../../assets/svg/SaveIcon';
import { ShoppingCartIcon } from '../../../assets/svg/ShoppingCartIcon';
import { UserIcon } from '../../../assets/svg/UserIcon';
import { WhiteHeartIcon } from '../../../assets/svg/WhiteHeartIcon';
import { PAGES_ENUM } from '../../../utils/constants';

interface IProfilePopupItem {
  text: string;
  icon: (props: any) => JSX.Element;
  border: boolean;
  link: string;
  func?: () => void;
}

export const useTranslatedProfileList = () => {
  const { t } = useTranslation();

  const profileList: IProfilePopupItem[] = [
    {
      text: t('profilePopup.profile'),
      icon: UserIcon,
      border: true,
      link: PAGES_ENUM.MY_PROFILE,
    },
    // {
    //   text: t('profilePopup.myVideoLessons'),
    //   icon: VideoLessonIcon,
    //   border: false,
    //   link: PAGES_ENUM.MY_COURSES,
    // },
    { text: t('profilePopup.myBooks'), icon: BookIcon, border: true, link: PAGES_ENUM.MY_PRODUCTS },
    {
      text: t('profilePopup.myCollections'),
      icon: SaveIcon,
      border: false,
      link: PAGES_ENUM.COLLECTIONS,
    },
    {
      text: t('profilePopup.favorites'),
      icon: WhiteHeartIcon,
      border: true,
      link: PAGES_ENUM.FAVORITES,
    },
    {
      text: t('profilePopup.cart'),
      icon: ShoppingCartIcon,
      border: false,
      link: PAGES_ENUM.CART,
    },
    {
      text: t('profilePopup.purchaseHistory'),
      icon: PurchaseHistoryIcon,
      border: true,
      link: PAGES_ENUM.ORDERS_HISTORY,
    },
  ];

  return { profileList };
};
