import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { CreateCollectionIcon } from '../../../../../assets/svg/CreateCollectionIcon';
import { NOTIFICATION_TYPES, useNotification } from '../../../../../hooks/useNotification';
import { useTypedDispatch } from '../../../../../hooks/useTypedDispatch';
import { useCreateCollectionMutation } from '../../../../../services/collections';
import { ErrorNode } from '../../../../../utils/notifications';
import { Button } from '../../../../Button';
import { Modal } from '../../../Modal';
import { deleteModal, setModal } from '../../../ModalSlice';
import { ModalTypes } from '../../../types/enums/ModalTypes';
import { CollectionFormInput } from '../CollectionFormInput';
import { CollectionModalLayout } from '../CollectionModalLayout';
import { useValidationService } from '../useValidationService';
import styles from './CreateCollectionModal.module.scss';

interface IProps {
  afterSuccessFunc?: {
    modalType: ModalTypes.SAVE_TO_COLLECTIONS;
    modalProps: { id: number; typeModal: string };
  } | null;
}

export const CreateCollectionModal: React.FC<IProps> = ({ afterSuccessFunc = null }) => {
  const { t } = useTranslation();
  const [create, resCreateCollection] = useCreateCollectionMutation();
  const validationSchema = useValidationService();

  const dispatch = useTypedDispatch();

  const onClose = () => {
    dispatch(deleteModal());
  };

  const onCreate = (values: { name: string; description: string }) => {
    if (values.description) {
      create(values);
    } else {
      create({ name: values.name });
    }
  };

  const afterSuccessOpenModal = () => {
    afterSuccessFunc && dispatch(setModal(afterSuccessFunc));
  };

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resCreateCollection.isSuccess,
    textSuccess: t('collections.modals.createCollection.successNotificationText'),
    afterCreate: afterSuccessFunc ? afterSuccessOpenModal : onClose,
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resCreateCollection.isError,
    error: resCreateCollection.error as ErrorNode,
  });

  return (
    <Modal
      ariaLabel={t('collections.modals.createCollection.title')}
      contentClassName={styles.createCollectionModal}
    >
      <CollectionModalLayout
        title={t('collections.modals.createCollection.title')}
        icon={<CreateCollectionIcon className={styles.createCollectionIcon} />}
      >
        <Formik
          initialValues={{
            name: '',
            description: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onCreate}
        >
          <Form className={styles.createCollectionForm}>
            <div className={styles.createCollectionFormInputs}>
              <CollectionFormInput
                type="text"
                name="name"
                placeholder={t('collections.modals.createCollection.nameFieldPlaceholder')}
              />
              <CollectionFormInput
                type="text"
                name="description"
                placeholder={t('collections.modals.createCollection.descriptionFieldPlaceholder')}
              />
            </div>
            <Button type="submit" className={styles.createCollectionFormButton} isOrange>
              {t('collections.modals.createCollection.createBtnText')}
            </Button>
          </Form>
        </Formik>
      </CollectionModalLayout>
    </Modal>
  );
};
