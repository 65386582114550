import { EmailIcon } from '../../../assets/svg/EmailIcon';
import { InstagramIcon } from '../../../assets/svg/InstagramIcon';
import { YoutubeIcon } from '../../../assets/svg/YoutubeIcon';
import { SingleInfo } from './SingleInfo';
import styles from './TopFooter.module.scss';

const topFooterContent = [
  {
    id: 'tf-youtube',
    title: 'footer.watchUsOnYouTube',
    icon: YoutubeIcon,
    altText: 'YouTube',
    infoLink: 'https://www.youtube.com/@SmartKids-Online',
    infoText: 'YouTube',
  },
  {
    id: 'tf-message',
    title: 'footer.communicationWriteToUs',
    icon: EmailIcon,
    altText: 'Email',
    infoLink: 'mailto:admin@smartkids-online.com',
    infoText: 'admin@smartkids-online.com',
    isEmail: true,
  },
  {
    id: 'tf-instagrram',
    title: 'footer.communicationFollowOurPage',
    icon: InstagramIcon,
    altText: 'Instagram',
    infoLink: 'https://www.instagram.com/smart_kids_online/',
    infoText: 'Instagram',
  },
];

export const TopFooter = () => {
  return (
    <div className={styles.topFooterInfo}>
      <div className="row g-0">
        {topFooterContent.map(item => {
          const { id, title, icon: Icon, infoLink, infoText, isEmail } = item;

          return (
            <SingleInfo
              key={id}
              title={title}
              icon={<Icon />}
              infoLink={infoLink}
              infoText={infoText}
              isEmail={isEmail}
            />
          );
        })}
      </div>
    </div>
  );
};
