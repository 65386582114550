import React from 'react';

import { Carousel } from '../../../../../components/Carousel';
import { IHomeCarousel } from '../../../../../models/IHome';
import styles from './AmazonBooksCarousel.module.scss';

export const AmazonBooksCarousel: React.FC<IHomeCarousel> = ({ children }) => {
  const settings = {
    dots: true,
    variableWidth: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <Carousel className={styles.amazonBooksCarouselContainer} settings={settings}>
      {children}
    </Carousel>
  );
};
