import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { EditIcon } from '../../../../../assets/svg/EditIcon';
import { NOTIFICATION_TYPES, useNotification } from '../../../../../hooks/useNotification';
import { useTypedDispatch } from '../../../../../hooks/useTypedDispatch';
import { useEditCollectionMutation } from '../../../../../services/collections';
import { ErrorNode } from '../../../../../utils/notifications';
import { Button } from '../../../../Button';
import { Modal } from '../../../Modal';
import { deleteModal } from '../../../ModalSlice';
import { CollectionFormInput } from '../CollectionFormInput';
import { CollectionModalLayout } from '../CollectionModalLayout';
import { useValidationService } from '../useValidationService';
import styles from './EditCollectionModal.module.scss';

interface IProps {
  name: string;
  description: string;
  id: number;
}

export const EditCollectionModal: React.FC<IProps> = ({ name, description, id }) => {
  const { t } = useTranslation();
  const [edit, resEditCollection] = useEditCollectionMutation();
  const validationSchema = useValidationService();

  const dispatch = useTypedDispatch();

  const onClose = () => {
    dispatch(deleteModal());
  };

  const onEdit = (values: { name: string; description: string }) => {
    if (values.description) {
      edit({ body: values, id });
    } else {
      edit({ body: { name: values.name }, id });
    }
  };

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resEditCollection.isSuccess,
    textSuccess: t('collections.modals.editCollection.successNotificationText'),
    afterCreate: () => dispatch(deleteModal()),
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resEditCollection.isError,
    error: resEditCollection.error as ErrorNode,
  });

  return (
    <Modal ariaLabel={t('collections.modals.editCollection.title')}>
      <CollectionModalLayout
        title={t('collections.modals.editCollection.title')}
        icon={<EditIcon className={styles.editCollectionIcon} />}
      >
        <Formik
          initialValues={{ name, description }}
          validationSchema={validationSchema}
          onSubmit={onEdit}
        >
          <Form className={styles.editCollectionForm}>
            <div className={styles.editCollectionFormInputs}>
              <CollectionFormInput
                type="text"
                name="name"
                placeholder={t('collections.modals.editCollection.nameFieldPlaceholder')}
              />
              <CollectionFormInput
                type="text"
                name="description"
                placeholder={t('collections.modals.editCollection.descriptionFieldPlaceholder')}
              />
            </div>
            <div className={styles.editCollectionFormButtons}>
              <Button
                type="button"
                className={styles.editCollectionFormButton}
                onClick={onClose}
                isOrange
              >
                {t('collections.modals.cancelBtnText')}
              </Button>
              <Button className={styles.editCollectionFormButton}>
                {t('collections.modals.confirmBtnText')}
              </Button>
            </div>
          </Form>
        </Formik>
      </CollectionModalLayout>
    </Modal>
  );
};
