import moment from 'moment';

export const useValidCommentTime = (time: number) => {
  const commentTimestamp = time;
  const currentDate = moment();

  const diffSeconds = currentDate.diff(moment.unix(commentTimestamp), 'seconds');

  const duration = moment.duration(diffSeconds, 'seconds');
  const years = Math.floor(duration.asYears());
  const monthsWithoutYears = Math.floor(duration.subtract(years, 'years').asMonths());
  const daysWithoutMonths = Math.floor(duration.subtract(monthsWithoutYears, 'months').asDays());
  const hoursWithoutDays = Math.floor(duration.subtract(daysWithoutMonths, 'days').asHours());
  const minutes = duration.minutes();

  let formattedDate = '';

  if (years > 0) {
    formattedDate += `${years} ${years === 1 ? 'year ' : 'years '}`;
  }

  if (monthsWithoutYears > 0) {
    formattedDate += `${monthsWithoutYears} ${
      monthsWithoutYears === 1 ? 'month ' : 'months '
    }`;
  }

  if (daysWithoutMonths > 0) {
    formattedDate += `${daysWithoutMonths} ${
      daysWithoutMonths === 1 ? 'day ' : 'days '
    }`;
  }

  if (years === 0 && monthsWithoutYears === 0 && daysWithoutMonths === 0 && hoursWithoutDays > 0) {
    formattedDate += `${hoursWithoutDays} ${
      hoursWithoutDays === 1 ? 'hour ' : 'hours '
    }`;
  }

  if (
    years === 0 &&
    monthsWithoutYears === 0 &&
    daysWithoutMonths === 0 &&
    hoursWithoutDays === 0 &&
    minutes > 0
  ) {
    formattedDate += `${minutes} ${
      minutes === 1 ? 'minute ' : 'minutes '
    }`;
  }

  if (formattedDate === '') {
    formattedDate = 'just now';
  } else {
    formattedDate += 'ago';
  }

  return { formattedDate };
};
